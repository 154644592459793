html {
  font-family: $font-family-base;
  font-size: 1em;
  color: $font-color-primary;
  text-shadow: 0 1px 2px rgba(#000, 0.35);

  @media screen and (max-width: $break-point-small) {
    font-size: 0.9em;
  }
}

body {
  padding-top: 1.75*$base-scale;
  padding-bottom: 4*$base-scale;
  background-color: #060606;
  background-image: radial-gradient(circle, rgba(33,68,99,1) 0%, rgba(6,6,6,1) 67%);
  background-position: center 0;
  background-size: 80rem 80rem;
  background-repeat: no-repeat;
}

.grid {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
}

.grid .grid {
  width: auto;
  min-width: 0;
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
}

.grid {
  *zoom: 1;

  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.unit {
  display: block;
  float: left;
  padding: 0 $grid-gutter;
  width: $unit-twelve;

  &.centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  color: $color-primary;
  text-decoration: none;
  transition: all .15s ease-out;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: $color-primary;
  }

  &:active {
    color: $color-primary-pressed;
    transition: none;
  }

  &.decorated {
    text-decoration: underline;
  }
}

.icon_defs {
  display: none;
  width: 0;
  height: 0;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  pointer-events: all;

  &.white {
    fill: #fff;
  }

  &.black {
    fill: #000;
  }

  &.red {
    fill: $color-red !important;
  }

  &.green {
    fill: $color-green !important;
  }

  &.blue {
    fill: $color-blue !important;
  }

  &.primary {
    fill: $color-primary !important;
  }
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;

  legend {
    font-size: $font-size-big;
    font-weight: bold;
    padding-top: 0.5*$base-scale;
    padding-bottom: 0.5*$base-scale;
    color: $header-font-color-primary;

    &.inline {
      margin-left: $unit-four;
      padding-left: $grid-gutter/2;
    }
  }
}

label {
  font-family: $font-family-input;
  font-size: $font-size-small;
  line-height: $base-scale;

  &.required {
    font-weight: bold;

    &:after {
      content: "*";
    }
  }

  &.right {
    text-align: right;
  }
}

input[type="file"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
textarea,
select, .autocomplete_select {
  position: relative;
  width: $unit-twelve;
  padding: 0.725*$grid-gutter 0.85*$grid-gutter;
  font-family: $font-family-input;
  font-size: $font-size-small;
  color: #555;
  background-color: #F2F6F7;
  outline: none;
  border: 1px solid rgba(#000, 0.1);
  border-radius: $radius-small;
  transition: all 0.15s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1rem;
  text-overflow: ellipsis;

  &:focus {
    background-color: lighten($color-primary, 45%);
    border: 1px solid lighten($color-primary, 15%);
    box-shadow: 0 0 0 1px lighten($color-primary, 10%);
    z-index: 1;
  }
}

textarea {
  resize: vertical;
  line-height: normal;
}

select:not([multiple="multiple"]), .autocomplete_select {
  padding-top: 0.7*$grid-gutter;
  padding-bottom: 0.7*$grid-gutter;
  padding-right: 0.9*$base-scale;
  background-color: #fff;
  background-image: image-url('select_arrow.svg'), linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.035));
  background-position: right center, 0 0;
  background-repeat: no-repeat, repeat-x;
  background-size: 20px 20px, auto auto;
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled {
    color: #aaa;
    opacity: 0.65;
  }
}

input::-webkit-input-placeholder, input::-moz-placeholder, input::placeholder {
  color: #ccc;
  @include font-smoothing(antialiased);
}

.form_field {
  position: relative;
  margin-bottom: 0.5*$base-scale;

  .field_label {
    position: relative;

    label {
      color: #424E57;
    }
  }

  .field_input {
    position: relative;

    select, .autocomplete_select {
      width: $unit-twelve;
    }

    .info_panel:last-child {
      margin-bottom: 0;
    }

    &[data-suffix] {
      display: flex;

      input {
        border-radius: $radius-small 0 0 $radius-small;
      }

      &:after {
        content: attr(data-suffix);
        display: flex;
        align-items: center;
        padding-left: 0.5*$grid-gutter;
        padding-right: 0.5*$grid-gutter;
        font-size: $font-size-small;
        white-space: nowrap;
        background-color: #F2F6F7;
        border: 1px solid $color-border;
        border-left-color: transparent;
        border-radius: 0 $radius-small $radius-small 0;
      }
    }
  }

  &.inline {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    .field_label {
      width: $unit-four;
      padding-right: $grid-gutter/2;

      &.right {
        text-align: right;
      }
    }

    .field_input {
      padding-left: $grid-gutter/2;
      width: $unit-eight;

      .data_label {
        margin-top: 0.15rem;
        margin-bottom: 0;
      }
    }

    &.checkbox {
      width: $unit-eight;
      margin-left: $unit-four;
    }

    &.multi_line {
      align-items: flex-start;

      .field_label {
        margin-top: 0.35rem;
      }
    }
  }

  &.compact {
    .icon.field_icon {
      position: absolute;
      margin-left: 0.65*$grid-gutter;
      margin-top: 0.85*$grid-gutter;
      z-index: 90;
      pointer-events: none;
      fill: #bbb;
    }

    input, select, textarea, .autocomplete_select {
      padding-left: 1.15*$base-scale;

      &:focus {
        .icon.field_icon {
          fill: #fff;
        }
      }
    }

    $width-checkbox: $base-scale;
    $width-checkbox-big: 1.5*$base-scale;
    $width-checkbox-medium: 0.75*$base-scale;
    $width-checkbox-small: 1rem;

    &.checkbox, &.radio_button {
      .field_input {
        display: inline-block;
        position: relative;
      }

      input[type="checkbox"], input[type="radio"] {
        padding: 0;
        margin: 0;
        width: $width-checkbox;
        height: $width-checkbox;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;

        + .icon {
          width: $width-checkbox;
          height: $width-checkbox;
          left: 0;
          top: 0;
          margin: 0;
          fill: rgba(#000, 0.2);
        }

        &:checked {
          + .icon {
            fill: $color-primary;
          }
        }
      }

      &.big {
        input[type="checkbox"], input[type="radio"] {
          width: $width-checkbox-big;
          height: $width-checkbox-big;

          + .icon {
            width: $width-checkbox-big;
            height: $width-checkbox-big;
          }
        }
      }

      &.medium {
        input[type="checkbox"], input[type="radio"] {
          width: $width-checkbox-medium;
          height: $width-checkbox-medium;

          + .icon {
            width: $width-checkbox-medium;
            height: $width-checkbox-medium;
          }
        }
      }

      &.small {
        input[type="checkbox"], input[type="radio"] {
          width: $width-checkbox-small;
          height: $width-checkbox-small;

          + .icon {
            width: $width-checkbox-small;
            height: $width-checkbox-small;
          }
        }
      }

      &.green {
        input[type="checkbox"], input[type="radio"] {
          &:checked {
            + .icon {
              fill: $color-green;
            }
          }
        }
      }

      &.red {
        input[type="checkbox"], input[type="radio"] {
          &:checked {
            + .icon {
              fill: $color-red;
            }
          }
        }
      }
    }
  }

  &.lined {
    margin: 0;
    margin-bottom: -1px;
    padding-top: 0.75*$grid-gutter;
    padding-bottom: 0.75*$grid-gutter;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
  }


  &.checkbox .field_input {
    display: flex;

    input[type="checkbox"] {
      flex: 1 0 16px;
      margin-top: 0.425*$grid-gutter;
      margin-right: $icon-gutter;
    }

    label {
      width: 100%;
      flex: 1 1 auto;
      color: #424E57;
    }
  }

  .date_picker {
    width: 100%;
  }
}

.form_actions {
  border-top: 1px solid $color-border;
  margin-top: 0.5*$base-scale;
  padding-top: 0.5*$base-scale;
  font-family: $font-family-input;
  font-size: $font-size-small;
  color: $font-color-secondary;

  &.plain {
    border-top: none;
    margin-top: 0;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a.cancel_button {
    padding: 0.5rem 1rem;
    font-weight: 600;
    color: $font-color-secondary;
    text-decoration: none;
    transition: all 0.075s ease-out;
    line-height: 1rem;
    border-radius: $radius-button;

    &:hover {
      color: $font-color-primary;
    }

    &:active {
      background-color: rgba(0,0,0,0.1);
      transform: scale(0.95);
    }

    &.small {
      padding: 0.25rem 0.75rem;
      font-size: $font-size-tiny;
    }
  }

  &.inline {
    padding-left: $unit-four;

    .inner {
      padding-left: $grid-gutter/2;
    }
  }

  &.centered {
    .inner {
      justify-content: center;
    }
  }
}

$color-bg-button: $color-primary;

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.7*$grid-gutter 1*$grid-gutter;
  font-family: $font-family-input;
  font-size: $font-size-small;
  font-weight: 600;
  color: #fff;
  background-color: $color-primary;
  border: none;
  outline: none;
  cursor: pointer;
  line-height: 1rem;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid $color-primary;
  border-radius: $radius-button;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  transition: all 0.075s ease-out;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: $color-primary-hover;
  }

  &:active {
    color: #fff;
    background-color: $color-primary-pressed;
    border-color: $color-primary-pressed;
  }

  &:disabled, &.disabled {
    cursor: normal;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon {
    margin-right: $icon-gutter;
    fill: #fff;
  }

  &.secondary {
    color: $font-color-primary;
    background-color: #fff;
    text-shadow: none;
    border-color: $color-border;

    &:hover {
      background-color: #EEF3F5;
    }

    &:active {
      background-color: #CAD7DC;
    }

    .icon {
      fill: $font-color-primary;
    }
  }

  &.small {
    padding: 0.4*$grid-gutter $grid-gutter;
    font-size: $font-size-tiny;
  }

  &.big {
    padding: 1.25*$grid-gutter 1.75*$grid-gutter;
    font-size: $font-size-medium;
  }

  &.rounded {
    padding: 0.4*$grid-gutter 0.75*$grid-gutter 0.4*$grid-gutter 0.5*$grid-gutter;
    border-radius: 1rem;
  }
}

.plain_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.1rem 0;
  font-size: $font-size-small;

  .icon {
    margin-right: $icon-gutter;
    fill: $color-primary;
  }

  &:hover {
    .icon {
      fill: $color-primary-hover;
    }
  }

  &:active {
    .icon {
      fill: $color-primary-pressed;
    }
  }

  &:hover, &:active {
    .project_status_label {
      .icon {
        fill: #fff;
      }
    }
  }

  &.icon_only {
    .icon {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }

  &.section_toggle:after {
    font-size: $font-size-big;
    margin-left: 0.25*$grid-gutter;
  }
}

.horizontal {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.highlight {
  background-image: linear-gradient(-100deg, rgba(#f2ba48, 0.3), rgba(#f2ba48, 0.7) 95%, rgba(#f2ba48, 0.1));
  border-radius: 13% $radius-small 20% $radius-small;
}

.overlay {
  display: none;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  &.show {
    display: block;
  }

  .overlay_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .close_button {
    position: absolute;
    left: $grid-gutter;
    top: $grid-gutter;
    z-index: 2;
    padding: 0.5rem;
    border-bottom: 0;
    line-height: 0;

    .icon {
      fill: #fff;
    }
  }

  iframe {
    box-shadow: 0 1px 50px rgba(#000, 0.075);
    border-radius: $radius-big;
  }
}

.content_block {
  width: $unit-eight;

  p:last-child {
    margin: 0;
  }

  @media screen and (max-width: $break-point-small) {
    width: $unit-twelve;
  }
}

.image_block {
  width: $unit-eight;
  margin-top: $base-scale;
  margin-bottom: $base-scale;

  &.full_width {
    width: $unit-twelve;
  }

  @media screen and (max-width: $break-point-small) {
    width: $unit-twelve;
  }
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $unit-twelve;
  height: 36.5vw;
  margin: $base-scale auto 2*$base-scale;

  @media screen and (min-width: 60rem) {
    height: 22rem;
  }

  @media screen and (max-width: $break-point-small) {
    height: 54vw;
    margin: $base-scale auto;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(#000, 0.1);
    border-radius: $radius-big;
    box-shadow: 0 1px 50px rgba(#000, 0.05);

    @media screen and (max-width: $break-point-small) {
      box-shadow: none;
    }
  }
}

#keyboard {
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 3*$grid-gutter;
    margin-top: -10rem;
    width: 80%;
    opacity: 0.75;

    @media screen and (max-width: $break-point-small) {
      margin-top: 1rem;
    }
  }
}

#about_me.unit, #about_you.unit {
  margin-bottom: $base-scale;
  width: 50%;

  @media screen and (max-width: $break-point-small) {
    width: 100%;
  }
}

#about_me.unit p:last-child, #about_you.unit p:last-child {
  margin: 0;
}

#welcome, #interactions_intro {
  font-size: 1.5rem;

  p {
    line-height: 2.2rem;
  }
}

#welcome {
  margin-bottom: $base-scale;
  text-align: center;
}

#avatar {
  width: 7rem;
  margin: 0 auto;

  img {
    border-radius: 50%;
    border: 1px solid #ddd;
    box-shadow: 0 2px 15px rgba(#000, 0.05), 0 2px 60px rgba(#000, 0.5);
  }
}

#say_my_name {
  margin-bottom: 1rem;
  font-size: $font-size-small;
  color: $font-color-light;

  .icon.heisenberg {
    position: relative;
    top: 0.2rem;
    fill: rgba(#fff, 0.85);
  }

  .my_name {
    color: rgba(#fff, 0.85);
  }

  @media screen and (max-width: $break-point-small) {
    font-size: $font-size-small;
  }

  a {
    margin-left: 0.15rem;
    border-bottom: none;

  .icon {
      display: inline-block;
      position: relative;
      top: 0.2rem;
      fill: $color-primary;
    }

    &:hover {
      .icon {
        fill: #fff;
      }
    }
  }
}

.unit.separator {
  padding-top: 0.5*$base-scale;
  padding-bottom: 0.5*$base-scale;
  width: $unit-eight;
  clear: both;

  hr {
    width: 6rem;
    border-top-width: 5px;
    margin-left: auto;
    margin-right: auto;
  }
}


h4 {
  line-height: $base-scale;
  margin: 0;
  font-size: $font-size-medium;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.intro_block {
  margin-bottom: 2*$base-scale;
  position: relative;
  text-align: center;
}

.launcher_app {
  h3 {
    text-align: center;
  }

  .launcher_app_icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 6rem;
    height: 6rem;
  }
}


#interactions_intro {
  text-align: center;
  margin-top: $base-scale;
  margin-bottom: 2*$base-scale;
}

#interactions {
  h2 {
    text-align: center;
  }

  .number {
    color:#f3aba9;
  }
}

#intro, #outro {
  h2, h4 {
    text-align: center;
  }

  h4 {
    margin-bottom: $base-scale;
  }

  .contact {
    margin-bottom: 1.5*$base-scale;
  }

  #signature {
    text-align: center;

    img {
      display: inline-block;
      margin-top: 0.75rem;
      width: 161pt;
      height: 36pt;
    }
  }
}

#find_me_on_the_web {
  margin-bottom: $base-scale;
  text-align: center;

  @media screen and (max-width: $break-point-small) {
    margin-bottom: $base-scale;
  }
}

.plain.contact {
  justify-content: center;

  @media screen and (max-width: $break-point-small) {
    flex-flow: column;
    margin-bottom: $base-scale;
  }

  li {
    margin: 0 0.5rem;

    @media screen and (max-width: $break-point-small) {
      margin: 0.5rem;
    }
  }

  .icon {
    position: relative;
    top: 0.15rem;
    fill: $color-primary;

    @media screen and (max-width: $break-point-small) {
      top: 0.2rem;
    }
  }

  a {
    &:hover {
      color: #fff;

      .icon {
        fill: #fff;
      }
   }
  }
}

#pitch_text {
  text-align: center;
}